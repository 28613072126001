@import '../../../Global';


.standard-select {
    overflow: hidden;
    margin: 0 0 1rem;
    &:last-child {
            margin-bottom: 2rem;
        }
    label {
        position: relative;
        font-size: 1.1rem;
        width: 35%;
        display: inline-block;
        @include media("<tablet") {
            font-size: 1rem;
            width: auto;
            display: block;
            margin-bottom: 0.5rem;
        }
    }
    &.disabled {
        label {
            opacity: 0.7;
        }
        .required {
            opacity: 0;
        }
    }
    select {
        width: 64%;
        float:right;
        padding: 0.5rem;
        box-sizing: border-box;
        //height: 36px;
        font-size: 1rem;
        font-family: $standardFont;
        border: 1px solid #ccc;
                &:focus {
                    border: 1px solid #333;
                }
        @include media("<tablet") {
            width: 100%;
            display: block;
            float: none;
            
        }
    }
    .required {
        font-weight: bold;
        color: red;
        text-indent: 0.3rem;
        display: inline-block;
    }
    &.error {
        label {
            color: red;
        }
        select {
            border: 2px solid red;
            background-color: #fff5f5;;
            &:focus {
                border: 2px solid red;
            }
            
        }
        strong {
            width: 64%;
            float: right;
            box-sizing: border-box;
            padding: 0.5rem;
            //height: 36px;
            font-size: 1rem;
            background-color: red;
            color: #fff;
            font-weight: 500;
            padding-top: 0.4rem;
            @include media("<tablet") {
                width: 100%;
                display: block;
                margin-bottom: 0.5rem;
            }
        }
    }
}