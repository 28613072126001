@import '../../Global';

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.form-section {
    &.fade-in {
        -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.5s; /* Firefox < 16 */
         -ms-animation: fadein 0.5s; /* Internet Explorer */
          -o-animation: fadein 0.5s; /* Opera < 12.1 */
             animation: fadein 0.5s;
    }
    .form-section-header {
        margin-bottom: 3rem;
        @include media("<tablet") {
            margin-bottom: 2rem;
          }
    }
    h1 {
        font-weight: 400;
        border-bottom: 1px solid #ccc;
        font-size: 2rem;
        padding-bottom: 1rem;
         margin-top: 0.5rem;
         @include media("<tablet") {
            font-size: 1.5rem;
            padding-bottom: 0.5rem;
          }
    }
    p {
        font-size: 1.1rem;
        white-space: pre-line;
        @include media("<tablet") {
            font-size: $smallFont;
          }
    }
}