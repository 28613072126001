@import '../../Global';

.modal {
    
    height: 100%;
    box-sizing: border-box;
    h1 {
        margin-top: 0;
        padding: 2rem 1rem;
        font-size: 2rem;
    color: #0076a8;
    font-weight: normal;
    margin-bottom: 0;
    border-bottom: 1px solid #707070;
     @include media("<tablet") {
         //line-height: 76px;
            font-size: 1.2rem;
        }
    }
    .scroll-wrapper {
        
        position: absolute;
    top: 100px;
    bottom: 100px;
    left: 0;
    right: 0;
    //border-top: 1px solid #ccc;
    border-bottom: 1px solid #707070;;
    @include media("<tablet") {
         top: 76px;
         bottom: 76px;
        }
    }
    .scroll-area {
        padding: 1rem;
        box-sizing: border-box;
        overflow: scroll;
        overflow-x: hidden;
    /* height: 100%; */
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        @include media("<tablet") {
          padding:1rem 1rem;
        }
        p{
            font-size: 1.1rem;
            a {
                color: #0076a8;
            }
        }
    }


    
}

.modal-overlay .modal-content .modal-footer button {
   // margin-right: 1rem;
   // margin-top: 18px;
    //background-color: #5f88ab;
    //color: #fff;
    border-radius: 0;
                border: 2px solid #0076a8;
                padding: 0.3em 1em;
                
                   background-color: #0076a8; 
                   color: #fff;
               margin: 1rem;
               line-height: normal;
               font-size: 15px;
               text-transform: capitalize;
    cursor: pointer;
    
}

.modal-overlay .modal-content .modal-footer {
    height: auto;
}


.modal-overlay .modal-close:after {
    cursor: pointer;
    text-indent: 0px;
    content: 'X';
    /* background-color: #000; */
    color: #0076a8;
    /* border: 2px solid #fff; */
    border-radius: 100%;
    width: 32px;
    height: 32px;
    text-align: center;
    position: absolute;
    line-height: 32px;
    right: 3rem;
    /* box-shadow: 0 0 8px -2px #000; */
    top: 1rem;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    font-size: 1.5rem;
    @include media("<tablet") {
        right: 2rem;
      }
}