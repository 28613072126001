@import '../../../Global';

body {
    margin-top: 0;
    background-color: #eee;
    font-family: "muli", sans-serif;

    font-weight: 400;

    font-style: normal;

 

}

.watersedge {
    
button {
    font-family: "muli", sans-serif;
}

.form-section {
    h1 {
        color: #0076a8;
        padding-bottom: .25rem;
        border-bottom: 1px solid #707070;
    }
}

.progress-track {
    font-family: $standardFont;
    // .progress-step {
    //     &:before {
    //         line-height: 22px;
    //     }
    //     &.error {
    //         &:before {
    //             background: #d50000;
    //             content: 'X';
    //             text-indent: 0px;
    //             font-size: 14px;
    //             color: #fff;
                
    //         }
    //     }
    // }
}

.big-vertical-buttons {
    margin: 2rem 0 2rem;
}

.big-vertical-button {
    // background-color: #eaeaea;
    // width: 100%;
    // border: none;
    // border-radius: 0.25rem;
    // position: relative;
    padding: 1rem;
    padding-right: 1rem;
    padding-left: 3.2rem;
    // box-sizing: border-box;
    // box-shadow: 0px 2px 18px -17px #000;
    // transition: transform 0.15s ease-in, box-shadow 0.15s ease-in;
    // transform: translate3d(0px, 0px, 0);
    cursor: pointer;
    &.selected {
        background-color: #0076a81a;
    }
    &:hover {
        background-color: #0076a80d;
    }
    .check-box {
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
        .check-square {
            border-radius: 100%;
            border: 1px solid #777;
            background-color: #fff;
            box-shadow: none;
            width: 24px;
            height: 24px;
            &.checked {
                &:after {
                    
                        opacity: 1;
                        border-radius: 100%;
                        transition: opacity 0.2s ease-in;
                        content: '';
                        background-image: none;
                        background-size: 100%;
                        background-color: #0076a8;
                        width: 16px;
                        height: 16px;
                        display: block;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                    
                }
            }
        }
    }
}


.field-set-expandable-list {
    .add-button {
        margin-bottom: 1rem;
        background-color: transparent;
        border: 0;
        color: #0076a8;
        font-size: 1.2rem;
        font-weight: bold;
        cursor: pointer;
        position: relative;
        margin-top: 0;
        text-transform: none;
            padding-left: 20px;
            &:after {
                content: '+';
                position: absolute;
                left: 0px;
                font-size: 1.3rem;
                //border-right: 2px solid #0076a8;
                //border-bottom: 2px solid #0076a8;
            }
    }
    .expandable-wrapper {
        .remove-button {
            position: absolute;
            right: 0;
            top: 1rem;
            margin-bottom: 1rem;
        background-color: transparent;
        border: 0;
        color: #0076a8;
        font-size: 1.2rem;
        font-weight: bold;
        cursor: pointer;
        margin-top: 0;
        text-transform: none;
            padding-left: 20px;
        }
    }
}


.step {
    .button-bar {
        border-top: 1px solid #707070;
        button {
            padding: 0;
        }
        .next-button {
            border: 2px solid #0076a8;
            background-color: #0076a8;
            border-radius: 0;
            line-height: normal;
            text-transform: capitalize;
            min-width: auto;
            padding: 0.3em 1em;
            font-size: 15px;
            
         }
         .previous-button {
            border: 2px solid #0076a8;
            color: #0076a8;
            border-radius: 0;
            line-height: normal;
            text-transform: capitalize;
            min-width: none;
            padding: 0.3em 1em;
            font-size: 15px;
         }
    } 
}



    .header {
        border-bottom: 1px solid #adadad;
        background-color: #fff;
        margin-bottom: 2.5rem;
        padding: 1rem;
        .header-buttons {
            display: block;
            position: absolute;
            top: 50% !important;
            transform: translateY(-50%);
            a {
                border-radius: 0;
                border: 2px solid #0076a8;
                padding: 0.3em 1em;
                &.dark-button {
                   background-color: #0076a8; 
                   color: #fff;
                }
            }
        }
        img {
            height: 32px;
    margin-top: -10px;
    @include media("<tablet") {
        height: auto;
        margin-top: 0;
        max-width: 200px;
        width: 35%;
    }
        }
        .header-body {
            padding-top: 1rem;
            @include media("<tablet") {
                padding-top: 0.3rem;
            }
        }
    }
    .form-wrapper {
        box-shadow: none;
        border-radius: 2rem;
    }
    .agreement-box {
        background-color: #0076a81a;
    }


.field-set-expandable-list {
    .expandable-wrapper {
        .remove-button {
            text-indent: -9999px;
            width: 60px;
            box-sizing: border-box;
            min-width: initial;
            &:after {
                position: absolute;
                right: 1rem;
                top: 0;
                content:'';
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4IDQ4OyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNDggNDgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxwYXRoIGQ9Ik0xNy43LDM1LjFDMTcuNywzNS4xLDE3LjcsMzUuMSwxNy43LDM1LjFjMC42LDAsMS0wLjUsMS0xTDE4LDIwLjFjMC0wLjYtMC41LTEtMS0wLjljLTAuNiwwLTEsMC41LTAuOSwxbDAuNywxMy45ICAgQzE2LjcsMzQuNiwxNy4yLDM1LjEsMTcuNywzNS4xeiIvPjxwYXRoIGQ9Ik0yOS45LDM1LjFDMzAsMzUuMSwzMCwzNS4xLDI5LjksMzUuMWMwLjYsMCwxLTAuNCwxLTAuOWwwLjctMTMuOWMwLTAuNi0wLjQtMS0wLjktMWMtMC42LDAtMSwwLjQtMSwwLjlMMjksMzQgICBDMjksMzQuNiwyOS40LDM1LDI5LjksMzUuMXoiLz48cGF0aCBkPSJNMjQsMzUuMWMwLjYsMCwxLTAuNCwxLTFWMjAuMWMwLTAuNi0wLjQtMS0xLTFzLTEsMC40LTEsMXYxMy45QzIzLDM0LjYsMjMuNCwzNS4xLDI0LDM1LjF6Ii8+PHBhdGggZD0iTTQxLDExLjZIMTAuN2wtMC43LDBjMCwwLDAsMCwwLDBjMCwwLDAsMCwwLDBIN2MtMC42LDAtMSwwLjQtMSwxczAuNCwxLDEsMWgyLjFsMi4zLDIzLjljMC4yLDIuNiwyLjQsNC42LDQuOSw0LjZoMTUgICBjMi42LDAsNC43LTIsNC45LTQuNmwyLjMtMjMuOUg0MWMwLjYsMCwxLTAuNCwxLTFTNDEuNiwxMS42LDQxLDExLjZ6IE0zNC4zLDM3LjNjLTAuMSwxLjYtMS40LDIuNy0yLjksMi43aC0xNSAgIGMtMS41LDAtMi44LTEuMi0yLjktMi43bC0yLjItMjMuN2wyNS40LDBMMzQuMywzNy4zeiIvPjxwYXRoIGQ9Ik0xOC42LDhoMTEuM2MwLjYsMCwxLTAuNCwxLTFzLTAuNC0xLTEtMUgxOC42Yy0wLjYsMC0xLDAuNC0xLDFTMTgsOCwxOC42LDh6Ii8+PC9nPjwvc3ZnPg==');
                width: 30px;
                height: 30px;
                display: block;
            }
        }
    }
}

.alert {
    font-size: 1.1rem;
    background-color: #0076a81a;
    @include media("<tablet") {
        font-size: 1rem;
    }
    &.prompt {
        background-color: transparent;
        padding: 0;
        white-space: pre-line;
        margin-top: -1.6rem;
        margin-bottom: 3rem;
        line-height: 1.5rem;
    }
    a {
        color: #0076a8;
    }
}
.account-selection {
    background-color: #eefaff;
}

.send-summary {
    .email-user {
        
        background-color: #0076a81a;
        }
    &.gray {
        background-color: #f6f6f6;
        .email-user {
            background-color: #ededed;
        }
    }
    background-color: #0076a81a;
    
}

p {
    line-height: 1.6rem;
    a {
        color: #0076a8;
    }
}


}