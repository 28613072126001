@import '../../../Global';

.standard-check {
    display: grid;
    grid-template-columns: 45px 1fr;
    margin-bottom: 1rem;
    margin-left: 35%;
    &.wide {
        margin-left: 0;
    }
    @include media("<tablet") {
        margin-left: 0;
    }
    input {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        width: 20px;
        height: 20px;
        @include media("<tablet") {
            margin-left: 0;
        }
    }
    label {
        cursor: pointer;
        font-size: 1.1rem;
        display: block;
        line-height: 20px;
        @include media("<tablet") {
            font-size: 1rem;
        }
    }
}