.email-signup {
    max-width: 500px;
    margin: 0 auto;
    padding: 1rem;
    box-sizing: border-box;
    background-color: aliceblue;
    margin-top: -1.5rem;
    h2 {
        font-size: 1.2rem;
        color: #0076a8;
        margin: 0;
        margin-bottom: 1rem;
    }
    P {
        margin-bottom: 0;
    }
    .standard-input {
        label {
            width: 0%;
            height: 0;
            margin: 0;
            padding: 0;
            display: none;
        }
        input {
            width: 100%;
            float:none;
            
        }
        &.error {
            strong {
                width: 100%;
            }
        }
        margin-bottom: 0;
    }
    button {
        border: 2px solid #0076a8;
        background-color: #0076a8;
        border-radius: 0;
        line-height: normal;
        text-transform: capitalize;
        min-width: auto;
        padding: 0.3em 1em;
        font-size: 15px;
        color: #fff;
        max-height: 30px;
        cursor: pointer;
    }
    .email-signup-layout {
        display: grid;
        grid-template-columns: 1fr 120px;
        align-items: center;
        column-gap: 1rem;
    }
    form {
        transition: opacity 0.25s ease-in;
    }
    .loading {
        opacity: 0.25;
        
    }
    .loading-area {
        position: relative;
        height: 50px;
    }

    .lds-ring {
        display: inline-block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 40px;
        height: 40px;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        margin: 4px;
        border: 4px solid #095ba0;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #095ba0 transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
}