@import '../../../Global';

.inline-rate-viewer {
    .rate-box {
        margin-top: -1.5rem;
        margin-bottom: 2rem;
        .special {
            display: inline-block;
            font-size: 0.75rem;
            background-color: #0076a829;
            color: #00364dc4;
            text-transform: uppercase;
            border-radius: 0.6rem;
            padding: 0.35rem 0.8rem;
        }
        .not-available {
            color: rgb(45, 45, 45);
            display: block;
            text-transform: uppercase;
            font-size: 0.75rem;
        }
    }
    .foot-note {
        width: 80%;
        margin: 0 auto;
        padding: 1rem;
        box-sizing: border-box;
        font-style: italic;
        @include media("<tablet") {
            width: 100%;
        }
        padding-bottom: 3rem;
    }
    .rate-wrapper {
        overflow: hidden;
        transition: height 0.35s ease-in, opacity 0.5s ease-in;
        position: relative;
        
        
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            box-shadow: inset 0px 13px 14px -16px;
        }
    }
    button {
        margin-bottom: 1rem;
        background-color: transparent;
        border: 0;
        color: #0076a8;
        font-size: 1.2rem;
        font-weight: bold;
        cursor: pointer;
        position: relative;
        
        &.toggle {
            padding-right: 36px;
            padding-left: 0;
            &:after {
                content: '';
                position: absolute;
                right: 8px;
                top: 25%;
                transform: translateY(-50%) rotate(45deg);
                width: 12px;
                height: 12px;
                border-right: 2px solid #0076a8;
                border-bottom: 2px solid #0076a8;
                transition: transform 0.25s ease-in;
            }
            &.open {
                &:after {
                    transform: translateY(5%) rotate(-135deg);
                }
            }
        }
        &.add {
            margin-top: 1.5rem;
            padding-left: 20px;
            &:after {
                content: '+';
                position: absolute;
                left: 0px;
                font-size: 1.3rem;
                //border-right: 2px solid #0076a8;
                //border-bottom: 2px solid #0076a8;
            }
        }
    }
    .rate-pane {
        table {
            width: 100%;
            margin: 0 auto;
    border-spacing: 0;
    border-bottom: 1px solid #ccc;
            tr:nth-child(even) {
               // background: #f6f6f6;
            }
            tr {
                &.product-row-start {
                    td {
                        border-top: 1px solid #ccc;
                    }
                    
                }
                &.disabled {
                    td {
                        color: #ccc;
                    }
                }
            }
            th {
                font-weight: bold;
                text-align: center;
                padding: 0.8rem;
                background-color: #f6f6f6;
                &.align-left {
                    text-align: left;
                }
            }
            td {
                text-align: center;
                padding: 0.8rem;
               &.row-title {
                background-color: #fff;
                font-weight: bold;
                text-align: left;
               }
            }
            
        }
    }

    .investment-product {
        position: relative;
        display: grid;
        grid-template-columns: 1.5fr 1fr 30px;
        column-gap: 2rem;
        margin-bottom: 1.5rem;

        @include media("<tablet") {
            grid-template-columns: 1fr;
            row-gap: 1rem;
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid #707070;
        }
        
        -webkit-animation: fadein 0.25s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.25s; /* Firefox < 16 */
          -o-animation: fadein 0.25s; /* Opera < 12.1 */
             animation: fadein 0.25s;


        label {
            .block-label {
                display: block;
                margin-bottom: 0.5rem;
            }
        }
        .investment-product-select {
            .select-wrapper {
                box-sizing: border-box;
                width: 100%;
                border-radius: 15px;
                padding: 2rem 0;
                border: 1px solid #CCC;
                height: 80px;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    right: 1rem;
                    top: 45%;
                    transform: translateY(-50%) rotate(45deg);
                    width: 12px;
                    height: 12px;
                    border-right: 2px solid #0076a8;
                    border-bottom: 2px solid #0076a8;
                    transition: transform 0.25s ease-in;
                    pointer-events: none;
                }
                &.error {
                    border: 1px solid red;
                    background-color: #fff5f5;
                }
            }
            select {
                outline: none;
                position: absolute;
                top: 1rem;
                left: 0;
                outline: none;
                border: 0;
                width: 100%;
                box-sizing: border-box;
                appearance: none;
                font-size: 1.4rem;
                line-height: 1.7rem;
                font-family: "muli", sans-serif;
                background-color: transparent;
                padding: 0 2rem 2rem;
                @include media("<tablet") {
                    font-size: 1.2rem;
                    padding: 0 1rem 2rem;
                }
            }
            .interest-display {
                font-size: 1rem;
                line-height: 1rem;
                position: absolute;
                bottom: 1rem;
                left: 2rem;
                @include media("<tablet") {
                    left: 1rem;
                }
            }
        }
        .investment-product-amount {
            position: relative;
            input {
                outline: none;
                box-sizing: border-box;
                width: 100%;
                border-radius: 15px;
                padding: 2rem;
                border: 1px solid #CCC;
                font-family: "muli", sans-serif;
                &:focus {
                    border: 1px solid #CCC;
                }
                height: 80px;
                font-size: 1.2rem;
                &::placeholder {
                    opacity: 0.5;
                }
                @include media("<tablet") {
                    height: 50px;
                    padding: 1rem 2rem;
                }
            }
            &.error {
                input {
                    border: 1px solid red;
                    background-color: #fff5f5;
                }
            }
            &:after {
                content: '$';
                position: absolute;
                line-height: 80px;
                left: 1rem;
                bottom: 0;
                font-size: 1.2rem;
                @include media("<tablet") {
                    line-height: 50px;
                }
            }
            &.disabled {
                &:after {
                    content: '';
                    position: absolute;
                    line-height: 80px;
                    left: 1rem;
                    bottom: 0;
                    font-size: 1.2rem;
                    @include media("<tablet") {
                        line-height: 50px;
                    }
                }
            }
        }
        .investment-product-option {
            position: relative;
            button {

                position: absolute;
                left: -16px;
                top: 50%;
                transform: translateY(-50%);
                width: 30px;
                height: 30px;
                margin-bottom: 0;
                padding: 0;
                overflow: hidden;
                //margin: 0 !important;
                box-sizing: border-box;
                margin-top: 5px;
            }
            svg {
                opacity: 0.5;
                width: 100%;
                display: block;
            }
            @include media("<tablet") {
                position: absolute;
                right: 1rem;
                top: -0.5rem;
            }
        }
    }
    .investment-total {
        margin-top: 2rem;
        margin-bottom: -2rem;
        border-top: 1px solid #707070;
        //border-bottom: 1px solid #707070;
        @include media("<tablet") {
            margin-bottom: -1rem;
        }
        dl {
            margin: 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
            padding: 1.5rem;
            dd, dt {
                display: block;
                color: #707070;
                font-size: 1.4rem;
                font-weight: bold;
            }
            
            dd {
                text-align: right;
                color: #0076a8;
                
            }
        }
    }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

