@import '../../../Global';

.radio-buttons {
    overflow: hidden;
    margin: 0 0 1rem;
    position: relative;
    .required {
        font-weight: bold;
        color: red;
        text-indent: 0.3rem;
        display: inline-block;
    }
    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        
    }
    legend {
        font-size: 1.1rem;
        
        position: relative;
        font-size: 1.1rem;
        width: 35%;
        float: left;
        @include media("<tablet") {
            font-size: 1rem;
            width: 100%;
            display: block;
            margin-bottom: 0.5rem;
        }
    }
    label {
        cursor: pointer;
    }
    .radio-wrapper-set {
        width: 64%;
    float: right;
    box-sizing: border-box;
    padding:  0.5rem;
    @include media("<tablet") {
        font-size: 1rem;
        width: 100%;
        display: block;
        margin-bottom: 0.5rem;
    }
    }
    .radio-wrapper {
        padding-left: 30px;
        position: relative;
        margin: 0 0 1rem 0;
        input {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}