
body {
    margin-top: 0;
}

.cfr {
    .header-buttons {
        position: absolute;
        top: 0 !important;
        a {
            border: 1px solid #fff !important;
            color: #fff !important;
            opacity: 0.7;
        }
    }
}
